body {
  background-color: #000000;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* ReactModal overlay CSS */
.Modal {
  position: absolute;
  top: 40px;
  left: 40px;
  right: 40px;
  bottom: 30px;
  background-color: rgba(0, 0, 0, 0.73);
  text-align: center;
  padding: 20px 20px 35px 20px;
}

@media (max-width: 640px) {
  .Modal {
    top: 100px;
  }
}

.Modal img {
  object-fit: contain;
  height: 100%;
  width: 100%;
}

.Modal .ModalClose,
.Modal-TraitChecker .ModalClose {
  margin-top: 20px;
  color: white;
  cursor: pointer;
  font-size: 14px;
  text-decoration: underline;
  font-family: overpassmono;
  position: absolute;
  bottom: 15px;
  left: calc(50% - 10px);
}

.Modal-TraitChecker {
  position: absolute;
  top: 15vh;
  left: 20vw;
  right: 20vw;
  bottom: 15vh;
  background-color: #151515;
  text-align: center;
  padding: 20px;
  overflow-y: auto;
  overflow-x: hidden;
}

@media (max-width: 640px) {
  .Modal-TraitChecker {
    top: 100px;
  }
}

.Overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.6);
}

@media (max-width: 1300px) {
  .Modal-TraitChecker {
    position: absolute;
    top: 15vh;
    left: 15vw;
    right: 15vw;
    bottom: 15vh;
  }
}

@media (max-width: 1000px) {
  .Modal-TraitChecker {
    position: absolute;
    top: 15vh;
    left: 10vw;
    right: 10vw;
    bottom: 15vh;
  }
}
